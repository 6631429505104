.codeEditor {
    margin-top: 15px;
    margin-left: 10px;
    border: 1px dotted;
    width: 1302px;
}

.vars {
    display: flex;
    justify-content: space-between;
}

.saveButton {
    margin-top: 10px;
    margin-left: 15px;
}

.scFData {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.scData {
    margin-right: 10px;
    margin-left: 10px;
}

.varsTable {
    margin-right: 10px;
    width: 630px;
    height: 500px;
}